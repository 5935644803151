import React from "react"

import { StaticImage } from "gatsby-plugin-image";

const Logotypes = ({heading, last, shadow}) => {
  return (
    <div className={`bg-white flex flex-col space-y-10 items-center justify-center px-5 sm:px-0 ${+ shadow ? "shadow-section py-3.5" : "py-10"}`}>
        {heading && <h6 className="capitalize text-black">{heading}</h6>}
        <div className="w-full max-w-sm lg:max-w-container grid grid-cols-2 gap-5 lg:flex lg:justify-between mx-5 sm:mx-10">
            <div className="logotype block mx-auto">
                <StaticImage src="../images/muddyboots.png" alt="Muddyboots" />
            </div>  
            <div className="logotype block mx-auto">
                <StaticImage src="../images/subsurface-dynamics.png" alt="Subsurface" />
            </div>  
            <div className="logotype block mx-auto logotype-grid-fix">
                <StaticImage src="../images/x.png" alt="X" />
            </div>  
            <div className="logotype block mx-auto">
                <StaticImage src="../images/seisware.png" alt="Seisware" />
            </div> 
            {last && <div className="logotype col-span-2 block mx-auto">
                <StaticImage src="../images/fieldshare.png" alt="Fieldshare" />
            </div>}
        </div>
    </div>  
  )
}

export default Logotypes;