import * as React from "react";
import Button from "./button";

import { StaticImage } from "gatsby-plugin-image";

const ParagraphWithImage = ({
  featureTitle,
  heading,
  subHeading,
  desc,
  buttonText,
  buttonLink,
  secondary,
  italic,
  section,
  children,
  additionalBottomSection,
}) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="bg-white border-t-11 border-black flex flex-col items-center justify-center max-w-container shadow-section w-full">
        <div className="flex flex-col items-center space-y-5 lg:space-y-0 lg:flex-row lg:space-x-20 mx-5 sm:mx-10 py-14 px-5 sm:px-10 w-full">
          <div className="w-full flex flex-col space-y-5 justify-center lg:py-20">
            {featureTitle && (
              <span className="text-button uppercase text-red font-bold">
                {featureTitle}
              </span>
            )}
            <h2 className="max-w-paragraph-heading">{heading}</h2>
            {subHeading && <p className="font-bold">{subHeading}</p>}
            <p className="max-w-paragraph-desc">{desc}</p>
            {buttonText && (
              <div className="pt-0 lg:pt-10">
                <Button
                  buttonText={buttonText}
                  buttonLink={buttonLink}
                  secondary={secondary}
                />
              </div>
            )}
            {!additionalBottomSection && <div>{children}</div>}
          </div>

          <div className="flex flex-col space-y-3 w-full">
            <div className="w-full">
              {section === "home_first" && (
                <StaticImage
                  src="../images/section-graphics-520.png"
                  alt="One Interface"
                  layout="fullWidth"
                  style={{ width: "100%" }}
                />
              )}
              {section === "home_second" && (
                <StaticImage
                  src="../images/Frame 1.png"
                  alt="All Data"
                  layout="fullWidth"
                  style={{ width: "100%" }}
                />
              )}
              {section === "home_third" && (
                <StaticImage
                  src="../images/data-wrangling-graphic-520.png"
                  alt="Data Wrangling"
                  layout="fullWidth"
                  style={{ width: "100%" }}
                />
              )}
              {section === "partners_first" && (
                <StaticImage
                  src="../images/ready-audience-graphic-520.png"
                  alt="Ready Audience"
                  layout="fullWidth"
                  style={{ width: "100%" }}
                />
              )}
              {section === "partners_second" && (
                <StaticImage
                  src="../images/establish-distribution-graphic-520.png"
                  alt="Establish Distribution"
                  layout="fullWidth"
                  style={{ width: "100%" }}
                />
              )}
              {section === "partners_third" && (
                <StaticImage
                  src="../images/fieldshare-testimonials-520.png"
                  alt="Ninja Api"
                  layout="fullWidth"
                  style={{ width: "100%" }}
                />
              )}
            </div>

            {italic && (
              <p className="text-image-description italic">{italic}</p>
            )}
          </div>
        </div>
        {additionalBottomSection && (
          <div className="w-full bg-bottom-section">
            <div className="p-5 sm:px-10 py-14">{children}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ParagraphWithImage;
