import * as React from "react";
import { Link } from "gatsby";

const Button = ({ buttonText, buttonLink, secondary }) => {
  return (
    <Link
      to={buttonLink}
      className={
        secondary
          ? "secondary-button"
          : "red-button hover:no-underline inline-flex"
      }
    >
      <button className="uppercase">
        {buttonText}
        {secondary ? <span>&nbsp;&nbsp;></span> : ""}
      </button>
    </Link>
  );
};

export default Button;
